<script>
import { mapState, mapActions } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import PaginationCustom from '@/components/table/Pagination.vue'
import appData from '@/data'

export default {
   name: 'IMSiup',
   components: {
      BCardCode,
      VueGoodTable,
      PaginationCustom,
   },
   data() {
      return {
         item: {},
         file: null,
         inputPeriode: '',
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
         columns: [
            {
               label: 'Nomor',
               field: 'nomor',
               sortable: false,
               thClass: 'align-middle',
            },
            {
               label: 'Tanggal',
               field: 'tanggal',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Nama',
               field: 'nama',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Nama PJ',
               field: 'nama_pj',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Klasifikasi',
               field: 'klasifikasi',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Kecamatan',
               field: 'kecamatan',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center align-middle',
            },
         ],
         filter: {
            kecamatan: null,
            periode: null,
            tahun: null,
            bulan: null,
         },
         filterOpt: {
            kecamatan: appData.filterIzinManual.kecamatan,
            periode: appData.filterIzinManual.periodeTerbit,
         },
      }
   },
   computed: {
      ...mapState({
         myData: state => state.IzinManual.items,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
      kecamatanNama() {
         const data = appData.kecamatanIzinManual
         return kecamatan => (data[kecamatan] ? data[kecamatan] : kecamatan)
      },
   },
   mounted() {
      this.loadItems()
   },
   methods: {
      ...mapActions({
         getData: 'IzinManual/getSiup',
      }),
      onDetail(id) {
         this.item = this.myData.find(item => item.id === id)
         console.log(this.item)

         this.$bvModal.show('modal-detail')
      },
      // VUE GOOD TABLE
      loadItems() {
         this.getData(this.filter)
      },
      onPeriodeChange(val) {
         console.log(val)
         this.inputPeriode = val
         this.filter.tahun = null
         this.filter.bulan = null
      },
      onFilter() {
         setTimeout(() => {
            this.loadItems()
         }, 100)
      },
   },
}
</script>

<template>
   <div>
      <b-card-code title="Data SIUP">
         <!-- search input -->
         <b-row>
            <!-- TOOLBAR -->
            <b-col cols="12" md="6">
               <b-form @submit.stop.prevent>
                  <div class="d-flex align-items-center justify-content-end">
                     <b-form-input
                        v-model="searchTerm"
                        class="d-inline-block mb-1"
                        placeholder="Search..."
                        autocomplete="off"
                        type="text"
                        aria-label="Search"
                     />
                  </div>
               </b-form>
            </b-col>
            <b-col cols="12" md="6">
               <div class="d-flex justify-content-end">
                  <b-button-group class="mb-1">
                     <b-button
                        v-b-tooltip.hover.top="'Refresh Data'"
                        variant="outline-secondary"
                        size="sm"
                        :disabled="isLoading"
                        @click="loadItems()"
                     >
                        <b-spinner v-if="isLoading" small />
                        <feather-icon v-if="!isLoading" icon="RefreshCwIcon" />
                     </b-button>
                     <b-button v-b-toggle.collapse-filter variant="outline-secondary" size="sm">
                        <feather-icon icon="FilterIcon" />
                     </b-button>
                     <b-dropdown variant="outline-secondary" right text="Menu" size="sm">
                        <b-dropdown-item :disabled="isLoading" @click="loadItems()">
                           Refresh
                        </b-dropdown-item>
                        <b-dropdown-item @click="onFilter()">
                           Filter
                        </b-dropdown-item>
                     </b-dropdown>
                  </b-button-group>
               </div>
            </b-col>

            <b-col>
               <b-collapse id="collapse-filter" class="mt-2">
                  <b-card class="border">
                     <b-row>
                        <b-col md="3">
                           <b-form-group label="Kecamatan" label-for="kecamatan">
                              <b-form-select
                                 v-model="filter.kecamatan"
                                 :options="filterOpt.kecamatan"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col md="3">
                           <b-form-group label="Periode Terbit" label-for="periode">
                              <b-form-select
                                 v-model="filter.periode"
                                 :options="filterOpt.periode"
                                 @change="onPeriodeChange"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col v-if="inputPeriode === 'tahun'" md="3">
                           <b-form-group label="Tahun Terbit" label-for="tahun">
                              <datepicker
                                 v-model="filter.tahun"
                                 :minimum-view="'year'"
                                 :maximum-view="'year'"
                                 format="yyyy"
                                 input-class="form-control"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col v-if="inputPeriode === 'bulan'" md="3">
                           <b-form-group label="Bulan Terbit" label-for="tahun">
                              <datepicker
                                 v-model="filter.bulan"
                                 :minimum-view="'year'"
                                 :maximum-view="'year'"
                                 format="yyyy"
                                 input-class="form-control"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                     </b-row>
                  </b-card>
               </b-collapse>
            </b-col>
         </b-row>

         <!-- table -->
         <vue-good-table
            ref="goodTable"
            :columns="columns"
            :rows="myData"
            :line-numbers="true"
            :pagination-options="{
               enabled: true,
               mode: 'pages',
            }"
            :search-options="{
               enabled: true,
               externalQuery: searchTerm,
            }"
         >
            <template slot="table-row" slot-scope="props">
               <span v-if="props.column.field == 'nomor'">{{ props.row.nomor }}</span>
               <span
                  v-else-if="props.column.field == 'tanggal'"
               >{{ props.row.tgl | moment("DD-MM-YYYY") }}</span>
               <span v-else-if="props.column.field == 'nama'">{{ (props.row.nama) }}</span>
               <span v-else-if="props.column.field == 'nama_pj'">{{ (props.row.nama_pj) }}</span>
               <span v-else-if="props.column.field == 'klasifikasi'">{{ (props.row.klasifikasi) }}</span>
               <span
                  v-else-if="props.column.field == 'kecamatan'"
               >{{ kecamatanNama(props.row.kecamatan) }}</span>

               <span v-if="props.column.field == 'action'">
                  <b-button
                     size="sm"
                     block
                     variant="outline-dark"
                     class="nowrap"
                     @click="onDetail(props.row.id)"
                  >
                     <feather-icon icon="EyeIcon" class="mr-50" />
                     <small>Detail</small>
                  </b-button>
               </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
               <pagination-custom
                  :total="props.total"
                  :page-length="pageLength"
                  :page-options="pageOptions"
                  :page-changed="props.pageChanged"
                  :per-page-changed="props.perPageChanged"
               />
            </template>
         </vue-good-table>
      </b-card-code>

      <b-modal
         id="modal-detail"
         scrollable
         size="lg"
         centered
         hide-footer
         title="Detail"
      >
         <b-list-group>
            <b-list-group-item>
               <b>Tanggal</b>
               : : {{ item.umur }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Umur</b>
               : {{ item.umur }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Status Kawin</b>
               : {{ item.status_kawin }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Pekerjaan</b>
               : {{ item.pekerjaan }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Pendidikan</b>
               : {{ item.pendidikan }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Jenis Izin</b>
               : {{ item.jenis }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Saran</b>
               : {{ item.saran }}
            </b-list-group-item>
         </b-list-group>
      </b-modal>

      <b-modal
         id="modal-import"
         scrollable
         size="lg"
         centered
         hide-footer
         title="Form Import"
         no-close-on-backdrop
      >
         <b-form-group label="File Excel">
            <b-form-file
               v-model="file"
               placeholder="Choose a file or drop it here..."
               drop-placeholder="Drop file here..."
            />
         </b-form-group>
         <hr>
         <b-button
            v-b-tooltip.hover.top="'Simpan'"
            variant="primary"
            type="button"
            class="float-right"
            :disabled="isLoading"
            @click.prevent="onSave"
         >
            <b-spinner v-if="isLoading" small />Import
         </b-button>
      </b-modal>
   </div>
</template>

<style scoped>
.nowrap {
   white-space: nowrap;
}
</style>

<style>
.hidden_header {
   display: none;
}
</style>
